
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { FormInput, FormSelect, FormPhone, FormYesNo } from "@/components/forms";
import { Getter } from "vuex-class";
import { Mandate } from "@/store/modules/mandate.store";
import { getMandateCandidates } from "@/utils/model-helpers";

interface VgeInvoiceData {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    billing_address: {
        street: string;
        number: string;
        bus: string;
        zip: string;
        country_code: string;
    };
    vat_number: string;
    vat_country_code: string;
}

@Component({
    components: {
        FormInput,
        FormSelect,
        FormPhone,
        FormYesNo,
    },
})
export default class CustomerInvoicePart extends Vue {
    @Getter("mandate/all") mandates!: Mandate[];
    @Getter("activity/viewing") activity!: Activity;

    @Prop() value?: VgeInvoiceData[] | null;

    @Prop({ default: false }) disabled?: boolean;

    localValue: VgeInvoiceData = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        billing_address: {
            street: "",
            number: "",
            bus: "",
            zip: "",
            country_code: "BE",
        },
        vat_number: "",
        vat_country_code: "BE",
    };

    isPrivate: boolean = true;

    mounted() {
        this.maybeUpdateCandidateDetails();
    }

    maybeUpdateCompanyDetails() {
        this.localValue.last_name = "";

        if (this.localValue.billing_address) {
            if (!this.mandateCandidates || this.mandateCandidates.length === 0) {
                return;
            }

            this.localValue.first_name = this.mandateCandidates[0]?.company ?? "";
            this.localValue.email = this.mandateCandidates[0]?.email ?? "";
            this.localValue.phone = this.mandateCandidates[0]?.phone ?? "";
            this.localValue.vat_number = this.mandateCandidates[0]?.vat ?? "";
            this.localValue.vat_country_code = "BE";

            this.localValue.billing_address.street = this.mandateCandidates[0]?.address.street ?? "";
            this.localValue.billing_address.number = this.mandateCandidates[0]?.address.number ?? "";
            this.localValue.billing_address.bus = this.mandateCandidates[0]?.address.additional ?? "";
            this.localValue.billing_address.zip = this.mandateCandidates[0]?.address.zip ?? "";
            this.localValue.billing_address.country_code = this.mandateCandidates[0]?.address.country ?? "BE";
        }
    }

    maybeUpdateCandidateDetails() {
        if (this.localValue.billing_address) {
            if (this.mandateCandidates && this.mandateCandidates.length === 0) {
                return;
            }

            this.localValue.first_name = this.mandateCandidates[0]?.first_name ?? "";
            this.localValue.last_name = this.mandateCandidates[0]?.last_name ?? "";
            this.localValue.email = this.mandateCandidates[0]?.email ?? "";
            this.localValue.phone = this.mandateCandidates[0]?.phone ?? "";

            this.localValue.billing_address.street = this.mandateCandidates[0]?.address.street ?? "";
            this.localValue.billing_address.number = this.mandateCandidates[0]?.address.number ?? "";
            this.localValue.billing_address.bus = this.mandateCandidates[0]?.address.additional ?? "";
            this.localValue.billing_address.zip = this.mandateCandidates[0]?.address.zip ?? "";
            this.localValue.billing_address.country_code = this.mandateCandidates[0]?.address.country ?? "BE";
        }
    }

    get viewingMandate() {
        const mandate = this.mandates.find((mandate) => mandate.activity_id === this.activity.id);

        if (!mandate) {
            return;
        }

        return mandate;
    }

    get mandateCandidates() {
        if (!this.viewingMandate) {
            return [];
        }

        return getMandateCandidates(this.viewingMandate);
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("isPrivate")
    handleTypeChanged(isPrivate: boolean) {
        if (!isPrivate) {
            this.maybeUpdateCompanyDetails();
        } else {
            this.maybeUpdateCandidateDetails();
        }
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: VgeInvoiceData | null) {
        this.localValue = newValue ?? {
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            billing_address: {
                street: "",
                number: "",
                bus: "",
                zip: "",
                country_code: "BE",
            },
            vat_number: "",
            vat_country_code: "BE",
        };
    }
}
